import type { FooterSegment } from 'src/config/footer';
import { getProductConfig } from 'src/config/product';

const cityGuideUrl = getProductConfig('cityGuideUrl');

const segments: FooterSegment[][] = [
  [
    {
      title: 'ftr_title_market_analysis', // i18n-extract-keys ["ftr_title_market_analysis"]
      name: 'dataAndAnalisys',
      icon: 'chart',
      links: [
        ...(cityGuideUrl
          ? [
              {
                label: 'ftr_anchor_house_prices', // i18n-extract-keys ["ftr_anchor_house_prices"]
                url: cityGuideUrl,
                alternate: true,
                isClientSideNavigation: true,
              },
            ]
          : []),
      ],
    },
  ],
];

export default { segments };
